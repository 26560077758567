<template>
  <div>
    <h1 class="display-3 d-flex align-items-center font-weight-light overflow-hidden position-absolute text-white" style="height: 66px; margin: 0 0 0 30px; top: 0; z-index: 1">
      <h6 class="h2 text-white d-inline-block mb-0"><i class="ni ni-single-02"></i> Publikasi</h6></h1>

    <base-header class="pb-6">
      <b-row>
        <b-col xl="11" md="11"> </b-col>
      </b-row>
    </base-header>

    <b-container fluid class="mt--6">
      <b-row>
        <b-col xl="12">
          <b-card>
            <template v-slot:header>
              <b-row>
                <b-col lg="6" cols="7">
                  <!-- <h6 class="surtitle">Overview</h6>
                  <h5 class="h3 mb-0">Total sales</h5> -->
                </b-col>
                <b-col lg="6" cols="7" style="display: flex; justify-content: flex-end" v-if="log_as == 'admin'">
                  <base-button size="md" type="primary" @click="handleAdd()"><i class="fa fa-plus" aria-hidden="true"></i>Tambah Publikasi</base-button>
                </b-col>
              </b-row>
            </template>
            <!-- <PublikasiTable /> -->

            <el-table
              :data="tableData"
              row-key="id"
              header-row-class-name="thead-light"
              @sort-change="sortChange"
            >

            

             <el-table-column label="No" type="index" width="100" />  


              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                v-bind="column"
              >
              </el-table-column>

              <el-table-column min-width="250px" label="Aksi">
                <div slot-scope="action">
                  <a :href="getBlobsFile(action.row.attachment_file_id)" target="blank" style="margin-right: 10px;">
                    <base-button type="primary" size="sm" icon> UNDUH </base-button>
                  </a>
                  <base-button type="warning" size="sm" icon  @click="handleEdit(action.row)" v-if="log_as == 'admin'">
                    UBAH
                  </base-button>
                  <base-button type="danger" size="sm" icon @click="doDelete(action.row)" v-if="log_as == 'admin'">
                    HAPUS
                  </base-button>
                </div>
              </el-table-column>
            </el-table>


            <div class="py-3">
              <el-select
                class="select-primary pagination-select mr-3"
                v-model="pagination.perPage"
                placeholder="Per page"
                @change="changeSizePage()"
                >
                <el-option
                    class="select-primary"
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                >
                </el-option>
              </el-select>

              <small class="card-category">
              Showing {{  1 }} to {{ tableData.length }} of {{ pagination.total }} entries
              </small>
            </div>

            <div style="display: flex;justify-content: flex-end;">
              <base-pagination
                  class="pagination-no-border"
                  :current="pagination.currentPage"
                  :per-page="pagination.perPage"
                  :total="pagination.total"
                  @change="paginationChanged($event)"
              >
              </base-pagination>
            </div>

            
          </b-card>
        </b-col>
      </b-row>
    </b-container>

    <!-- Tambah Publikasi -->
    <Modal
      :show="showAddPublicationModal"
      modalContentClasses="mdl-content"
      modalClasses="modal-dialog-scrollable"
      headerClasses="pb-0"
      @close="showAddPublicationModal = false"
      size="lg"
    >
      <template v-slot:header>
        <div class="modal-title">
          <h4 class="mdl-title">Tambah Publikasi</h4>
        </div>
      </template>
      <div class="px-4 border-top pt-4">
        <div class="form-group row">
          <label class="col-form-label form-control-label">Judul Publikasi</label>
          <input type="text" class="form-control" v-model="addTitle" />
        </div>
        <div class="form-group row">
          <label class="col-form-label form-control-label">Lampiran Publikasi</label>
          <div class="w-100">
            <base-input rules="required" name="File">
              <file-input
                accept="application/pdf"
                ref="publication_attachment_file"
                @change="uploadFileBlob(file.publication, 'publication_attachment_file')"
              ></file-input>
            </base-input>
            <small v-if="file.publication.publication_attachment_file"
                class="d-block mt-3 mb-2 text-justify">
                <a :href="getBlobsFile(file.publication.publication_attachment_file)"
                  target="_blank" class="rounded bg-primary p-2 text-white mt-2">Lihat file</a>
            </small>
          </div>
        </div>
        <div class="form-group row">
          <button class="btn btn-primary" @click="doAdd()">Simpan</button>
        </div>
      </div>
    </Modal>

    <!-- Edit Publikasi -->
    <Modal
      :show="showEditPublicationModal"
      modalContentClasses="mdl-content"
      modalClasses="modal-dialog-scrollable"
      headerClasses="pb-0"
      @close="showEditPublicationModal = false"
      size="lg"
    >
      <template v-slot:header>
        <div class="modal-title">
          <h4 class="mdl-title">Edit Publikasi</h4>
        </div>
      </template>
      <div class="px-4 border-top pt-4">
        <div class="form-group row">
          <label class="col-form-label form-control-label"
            >Judul Publikasi</label
          >
          <input type="text" class="form-control" v-model="updateTitle" />
        </div>
        <div class="form-group row">
          <label class="col-form-label form-control-label"
            >Lampiran Publikasi</label
          >
          <div class="w-100">
            <file-input
                accept="application/pdf"
                ref="publication_attachment_file_update"
                @change="uploadFile(file.publication, 'publication_attachment_file')"
            ></file-input>
          </div>
          <small v-if="file.publication.publication_attachment_file"
            class="d-block mt-3 mb-2 text-justify">
            <a :href="getBlobsFile(file.publication.publication_attachment_file)"
              target="_blank" class="rounded bg-primary p-2 text-white mt-2">Lihat file</a>
          </small>
        </div>
        <div class="form-group row">
          <button class="btn btn-primary" @click="doEdit()">EDIT</button>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import API from "../../../api/base_url";
import {Table, TableColumn, Select, Option} from "element-ui";
import BaseHeader from "@/components/BaseHeader";
import { BasePagination } from "@/components";
import clientPaginationMixin from "../../Tables/PaginatedTables/clientPaginationMixin"
import FileInput from "@/components/Inputs/FileInput";
import PublikasiTable from "../../Tables/AdminTable/Publikasi.vue";
import Modal from "@/components/Modal.vue";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import baseURL from "@/api/base_url_backend.js";


export default {
  mixins: [clientPaginationMixin],
  components: {
    FileInput,
    BaseHeader,
    BasePagination,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option,
    PublikasiTable,
    Modal,
  },
  mounted() {
    this.getData()
    this.log_as = localStorage.getItem('as')
  },
  data() {
    return {
      file: {
        publication: {
          publication_attachment_file: ''
        }
      },
      log_as: "",
      getDetail : {},
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 20, 50],
        total: 1
      },
      showAddPublicationModal: false,
      showEditPublicationModal: false,
      tableColumns: [
          {
            prop: "title",
            label: "Judul",
            minWidth: 500,
          },
        ],
        tableData: [],
        selectedRows: [],
        addTitle:"",
        updateTitle:"",
        updateFile:"",
        updateId:"",
        updateStatus:"",
        formData: new FormData()
    };
  },
  methods: {
    // start upload
    uploadFileBlob(form, field) {
        const fileInput = this.$refs[field];
        const file = fileInput.files[0];

        const allowedTypes = ['image/jpeg', 'image/png', 'image/gif', 'application/pdf'];
        if (!allowedTypes.includes(file.type)) {
          Swal.fire("Kesalahan", "Hanya file PDF dan gambar yang diizinkan", "error");
          return;
        }

        const maxSize = 5 * 1024 * 1024;
        if (file.size > maxSize) {
          Swal.fire("Kesalahan", "Ukuran file tidak boleh lebih dari 5MB", "error");
          return;
        }

        const formData = new FormData();
        formData.append("file", file);
        this.isLoading = true;
        API.post(`blobs/upload/public`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then((res) => {
            let data = res.data.data;
            let value = data.id;
            this.$set(form, field, value);
            this.isLoading = false;
          })
          .catch((err) => {
            this.isLoading = false;
            Swal.fire("Terjadi Kesalahan", "Mohon upload ulang kembali", "error");
          });
    },
    getBlobsFile(filename) {
      let url = `${baseURL}blobs/download/${filename}`
      return url
    },
    resetFileInput(ref) {
    if (this.$refs[ref]) {
      // this.$refs.lulus_uji_file_id.files = []
      this.$refs[ref].files = []
      const inputElement = this.$refs[ref].$el.querySelector('input[type="file"]');
      inputElement.value = ''
    }
    },
    resetAllFileInputs(refs) {
      refs.forEach(ref => this.resetFileInput(ref));
    },
    // end of upload
    getData() {
      let headers = {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
        API.get(`publications?page=${this.pagination.currentPage}&page_size=${this.pagination.perPage}` , {headers})
          .then(({data}) => {
            if (data.data == null) {
              this.tableData = []
            } else {
              this.tableData = data.data
              this.pagination.total = data.meta.pagination.total_items
            }
          })
          .catch((err) => {
            console.log(err);
        })
    },
    changeSizePage() {
      this.getData()
    },
    paginationChanged(page) {
      this.pagination.currentPage = page;
      this.getData()
    },
    handleAdd() {
      this.showAddPublicationModal = true
    },
    handleEdit(data) {
      this.showEditPublicationModal = true
      let headers = {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
        API.get(`publications/${data.id}` , {headers})
          .then(({data:content}) => {
              this.updateTitle = content.data.title
              this.updateFile = content.data.attachment
              this.updateId = content.data.id
              this.updateStatus = content.data.status
            })
          .catch((err) => {
            console.log(err);
      })
    },
    doAdd() {
      if (!this.addTitle) {
        Swal.fire('Data anda tidak lengkap!','Wajib mengisi semua data.','error')
      } else {
        Swal.fire({
          title: 'Tambah Publikasi?',
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: 'Simpan',
          denyButtonText: `Batal`,
        }).then((result) => {
          if (result.isConfirmed) {
            let headers = {
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }

            let payload = {
              attachment_file_id: "36f8ded9-a759-410b-af79-3055fcf25795",
              is_active: 1,
              title: this.addTitle
            }
  
            API.post('publications', payload, {headers})
              .then(({data}) => {
                Swal.fire('Berhasil', '', 'success')
                this.addTitle = ""
                this.getData()
                this.showAddPublicationModal = false
              })
              .catch((err) => {
                Swal.fire('Gagal mendapatkan Data','Sistem gagal menampilkan data, terjadi kesalahan','error')
                this.isLoading = false
              })
  
  
          }
        })
      }
    },
    doEdit() {
      let headers = {
          Authorization: `Bearer ${localStorage.getItem('token')}`
      }


      let payload = {
        "attachment_file_id": "36f8ded9-a759-410b-af79-3055fcf25795",
        "is_active": +this.updateStatus,
        "title": this.updateTitle
      }
  

        Swal.fire({
        title: 'Merubah Data ? ',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'RUBAH',
        cancelButtonText: 'BATAL'
      }).then((result) => {
        if (result.isConfirmed) {
          API.put(`publications/${this.updateId}` , payload, {headers})
          .then(({data:content}) => {
            Swal.fire('Data berhasil dirubah!','','success')

            this.getData()

            this.updateId = ""
            this.updateStatus = ""
            this.updateTitle = ""

            this.showEditPublicationModal = false
          })
          .catch((err) => {
            Swal.fire(
            'Data gagal diEdit!',
            'Your file has not been edited.',
            'error'
            )
        })
        }
      })
      this.showEditPublicationModal = false

    },
    doDelete(data) {
      Swal.fire({
        title: 'Hapus Data ini ? ',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'HAPUS',
        cancelButtonText: 'BATAL'
      }).then((result) => {
        if (result.isConfirmed) {
          let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
          API.delete(`publications/${data.id}` , {headers})
          .then(({data:content}) => {
            Swal.fire(
            'Berhasil Menghapus',
            '',
            'success'
          )

          this.getData()
          })
          .catch((err) => {
            console.log(err);
          })

          
        }
      })
    }
    
  },
};
</script>


